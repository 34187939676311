import React, { useEffect } from "react";
import SelfieIcon from "../../assets/images/png/selfie.png";
import LampIcon from "../../assets/images/png/lamp.png";
import GlassesIcon from "../../assets/images/png/glasses.png";
import { EnrollmentProcessor } from "../../core-processors/EnrollmentProcessor";
import Stepper from "../Stepper";
import DefaultLayout from "../../layouts/default";
import { FaceTecSDK } from "../../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import { Config } from "./Config";
import axios from "axios";
import { handleTheme } from "../../config/themeConfig";
import { FaceTecStrings } from "../../config/FaceTecStrings.pt-br";
import path from 'path';
var Enrollment = function (_a) {
    var onSuccessCallback = _a.onSuccessCallback;
    var steps = [
        {
            icon: SelfieIcon,
            title: "Por segurança, precisamos que tire uma selfie",
            subTitle: "A selfie é para confirmar que você é o titular da conta.",
        },
        {
            icon: LampIcon,
            title: "Encontre um local com boa iluminação",
            subTitle: "Para uma selfie perfeita uma boa iluminação é fundamental.",
        },
        {
            icon: GlassesIcon,
            title: "Não use acessórios ou faça expressões",
            subTitle: "Retire óculos, chapéu, boné e quaisquer acessórios que cobrem sua cabeça e rosto.",
        },
    ];
    var _b = React.useState({
        token: "",
    }), envConfig = _b[0], setEnvConfig = _b[1];
    var _c = React.useState(false), sessionInit = _c[0], setSessionInit = _c[1];
    var _d = React.useState(0), activeStep = _d[0], setActiveStep = _d[1];
    useEffect(function () {
        console.log("useEffect e", Config);
        var getSessionToken = function () {
            console.log("chamou o token");
            var axiosApi = axios.create({
                baseURL: "https://api.facetec.com/api/v3.1/biometrics",
            });
            axiosApi
                .get("/session-token", {
                headers: {
                    "Content-Type": "application/json",
                    "X-Device-Key": Config.DeviceKeyIdentifier,
                    "X-User-Agent": FaceTecSDK.createFaceTecAPIUserAgentString(""),
                },
            })
                .then(function (r) { return setEnvConfig({ token: r.data.sessionToken }); });
        };
        var initFaceTec = function () {
            // const authAlias = `../../../core-sdk/`;
            console.log(".." + __dirname + "core-sdk/FaceTecSDK.js/resources");
            console.log(path.resolve(__dirname));
            FaceTecSDK.setResourceDirectory(".." + __dirname + "core-sdk/FaceTecSDK.js/resources");
            FaceTecSDK.setImagesDirectory(".." + __dirname + "core-sdk/FaceTec_images");
            FaceTecSDK.setCustomization(handleTheme());
            console.log("chamou a inicializacao");
            FaceTecSDK.initializeInDevelopmentMode(Config.DeviceKeyIdentifier, Config.PublicFaceScanEncryptionKey, function (initializedSuccessfully) {
                console.log("chamou", initializedSuccessfully);
                if (initializedSuccessfully) {
                    setSessionInit(true);
                    getSessionToken();
                    FaceTecSDK.configureLocalization(FaceTecStrings);
                }
            });
        };
        if (FaceTecSDK.getStatus() === 1) {
            setSessionInit(true);
        }
        if (FaceTecSDK.getStatus() === 0 && !sessionInit) {
            if (envConfig.token && !sessionInit) {
                console.log("initFaceTec");
                initFaceTec();
            }
            else {
                getSessionToken();
            }
        }
        else if (envConfig.token.length === 0) {
            getSessionToken();
        }
    }, [sessionInit, envConfig]);
    function generateUUId() {
        //@ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
            return (c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
        });
    }
    function handleStartEnrollment() {
        console.log("handleStartEnrollment", sessionInit);
        if (sessionInit) {
            new EnrollmentProcessor({
                sessionToken: envConfig.token,
                userId: generateUUId(),
                onSuccessCallback: onSuccessCallback,
            });
        }
    }
    console.log("envConfig", envConfig);
    var handleNextStep = function () {
        if (activeStep + 1 === steps.length) {
            handleStartEnrollment();
        }
        setActiveStep(activeStep + 1);
    };
    return (React.createElement(DefaultLayout, null,
        React.createElement(Stepper, { steps: steps, activeStep: activeStep, onNextStep: handleNextStep })));
};
export default Enrollment;
