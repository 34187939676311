var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var ButtonStyled = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 38px;\n  font-size: 0.875rem;\n  border: none;\n  border-radius: 4px;\n  font-family: 'Montserrat';\n  font-weight: 600;\n  cursor: pointer;\n\n  ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 38px;\n  font-size: 0.875rem;\n  border: none;\n  border-radius: 4px;\n  font-family: 'Montserrat';\n  font-weight: 600;\n  cursor: pointer;\n\n  ",
    ";\n"])), function (_a) {
    var variant = _a.variant;
    if (variant === 'contained') {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n\n      :hover {\n        background-color: ", ";\n        filter: brightness(0.9);\n      }\n    "], ["\n      background-color: ", ";\n      color: ", ";\n\n      :hover {\n        background-color: ", ";\n        filter: brightness(0.9);\n      }\n    "])), function (_a) {
            var theme = _a.theme;
            return theme.instructions.actionButton.backgroundColor;
        }, function (_a) {
            var theme = _a.theme;
            return theme.instructions.actionButton.textColor;
        }, function (_a) {
            var theme = _a.theme;
            return theme.instructions.actionButton.backgroundColor;
        });
    }
    if (variant === 'text') {
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        background-color: transparent;\n        color: ", ";\n\n        :hover {\n          background-color: rgba(0, 98, 255, 0.04);\n        }\n      "], ["\n        background-color: transparent;\n        color: ", ";\n\n        :hover {\n          background-color: rgba(0, 98, 255, 0.04);\n        }\n      "])), function (_a) {
            var theme = _a.theme;
            return theme.textColor;
        });
    }
});
var templateObject_1, templateObject_2, templateObject_3;
