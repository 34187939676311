import { FaceTecSDK } from "../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import axios from "axios";
import { Config } from "../components/Enrollment/Config";
var EnrollmentProcessor = /** @class */ (function () {
    function EnrollmentProcessor(_a) {
        var _this = this;
        var sessionToken = _a.sessionToken, onSuccessCallback = _a.onSuccessCallback, userId = _a.userId;
        this.latestNetworkRequest = new XMLHttpRequest();
        this.onFaceTecSDKCompletelyDone = function () {
            _this.success = _this.latestSessionResult.isCompletelyDone;
            if (_this.success) {
                _this.onSuccessCallback();
            }
        };
        this.isSuccess = function () {
            return _this.success;
        };
        this.success = false;
        this.userId = userId;
        this.onSuccessCallback = onSuccessCallback;
        this.latestSessionResult = null;
        new FaceTecSDK.FaceTecSession(this, sessionToken);
    }
    EnrollmentProcessor.prototype.processSessionResultWhileFaceTecSDKWaits = function (sessionResult, faceScanResultCallback) {
        this.latestSessionResult = sessionResult;
        if (sessionResult.status !==
            FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
            faceScanResultCallback.cancel();
            return;
        }
        var parameters = {
            faceScan: sessionResult.faceScan,
            auditTrailImage: sessionResult.auditTrail[0],
            lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
            sessionId: sessionResult.sessionId,
            externalDatabaseRefID: this.userId,
        };
        var axiosApi = axios.create({
            baseURL: Config.BaseURL,
        });
        axiosApi
            .post("/enrollment-3d", parameters, {
            headers: {
                "Content-Type": "application/json",
                "X-Device-Key": Config.DeviceKeyIdentifier,
                "X-User-Agent": FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId),
            },
            onUploadProgress: function (e) {
                var progress = e.loaded / e.total;
                faceScanResultCallback.uploadProgress(progress);
            },
        })
            .then(function (response) {
            var scanResultBlob = response.data.scanResultBlob;
            if (response.data.wasProcessed) {
                FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage("Liveness\nConfirmed");
                faceScanResultCallback.proceedToNextStep(scanResultBlob);
            }
            else {
                console.log("Unexpected API response, cancelling out.");
                faceScanResultCallback.cancel();
            }
        })
            .catch(function () { return faceScanResultCallback.cancel(); });
    };
    return EnrollmentProcessor;
}());
export { EnrollmentProcessor };
