var theme = {
    logo: 'src/assets/images/svg/logo-horizontal.svg',
    cancelButton: 'src/assets/images/png/close.png',
    backgroundColor: '#ffffff',
    textColor: '#7b7b7b',
    backgroundInitialLoading: '#ffffff',
    foregroundInitialLoading: '#0062ff',
    instructions: {
        backgroundColor: '#ffffff',
        actionButton: {
            backgroundColor: '#0062ff',
            hoverColor: '#003385',
            disabledColor: 'rgb(0, 98, 255, 0.25)',
            textColor: '#ffffff'
        },
        frame: {
            borderColor: '#ffffff',
            borderRadius: '0px',
            borderWidth: '0px',
            backgroundColor: '#ffffff',
        },
        feedbackBar: {
            backgroundColor: 'rgb(0, 98, 255, 0.8)',
            borderRadius: '4px',
            shadow: '0 0',
            textColor: '#ffffff',
            fontFamily: ''
        },
        ovalCam: {
            strokeColor: '#0062ff',
            strokeWidth: 5,
            spinner1: '#0062ff',
            spinner2: '#0062ff',
            spinnerStrokeWidth: 5,
        },
    },
    retry: {
        borderColor: '#0062ff',
        ovalStrokeColor: '#0062ff'
    },
    finishingUp: {
        backgroundColor: '#ffffff',
        textColor: '#7b7b7b',
        circle: '#0062ff',
        uploadProgress: '#0062ff',
    }
};
export { theme };
