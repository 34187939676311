var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var StepContainerStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  max-width: 360px;\n  height: 812px;\n  padding: 24px 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  max-width: 360px;\n  height: 812px;\n  padding: 24px 16px;\n"])));
export var StepContentStyled = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n  height: 465px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  width: 100%;\n  height: 465px;\n"])));
export var IconContentStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  height: 270px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  width: 100%;\n  height: 270px;\n"])));
export var InfoContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 146px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 146px;\n"])));
export var TitleInfoStyled = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #7b7b7b;\n  text-align: center;\n  padding: 0 20px;\n  font-family: 'Montserrat';\n"], ["\n  color: #7b7b7b;\n  text-align: center;\n  padding: 0 20px;\n  font-family: 'Montserrat';\n"])));
export var SubtitleInfoStyled = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #7b7b7b;\n  width: 234px;\n  font-size: 0.875rem;\n  text-align: center;\n  padding: 0 47px;\n  font-family: 'Montserrat';\n"], ["\n  color: #7b7b7b;\n  width: 234px;\n  font-size: 0.875rem;\n  text-align: center;\n  padding: 0 47px;\n  font-family: 'Montserrat';\n"])));
export var ActionsContentStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"], ["\n  width: 100%;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
export var ArrowRightStyled = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 10px;\n"], ["\n  margin-right: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
