import { FaceTecSDK } from "../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import { theme } from '../commons/constants/theme';
export var handleTheme = function () {
    var defaultCustomization = new FaceTecSDK.FaceTecCustomization();
    var customizeInitialLoading = function () {
        defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = theme.backgroundInitialLoading;
        defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = theme.foregroundInitialLoading;
    };
    var customizeInstructions = function () {
        defaultCustomization.frameCustomization.backgroundColor = theme.backgroundColor;
        defaultCustomization.frameCustomization.borderCornerRadius = theme.instructions.frame.borderRadius;
        defaultCustomization.frameCustomization.borderColor = theme.instructions.frame.borderColor;
        defaultCustomization.frameCustomization.borderWidth = theme.instructions.frame.borderWidth;
        defaultCustomization.overlayCustomization.brandingImage = theme.logo;
        defaultCustomization.overlayCustomization.backgroundColor = theme.backgroundColor;
        defaultCustomization.guidanceCustomization.foregroundColor = theme.textColor;
        defaultCustomization.guidanceCustomization.backgroundColors = theme.instructions.frame.backgroundColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = theme.instructions.actionButton.backgroundColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = theme.instructions.actionButton.disabledColor;
        defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = theme.instructions.actionButton.hoverColor;
        defaultCustomization.guidanceCustomization.buttonTextNormalColor = theme.instructions.actionButton.textColor;
        defaultCustomization.guidanceCustomization.buttonTextDisabledColor = theme.instructions.actionButton.textColor;
        defaultCustomization.guidanceCustomization.buttonTextHighlightColor = theme.instructions.actionButton.textColor;
        defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm);
    };
    var customizeRetry = function () {
        defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = theme.retry.borderColor;
        defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = theme.retry.ovalStrokeColor;
        defaultCustomization.ovalCustomization.strokeColor = theme.instructions.ovalCam.strokeColor;
        defaultCustomization.ovalCustomization.strokeWidth = theme.instructions.ovalCam.strokeWidth;
        defaultCustomization.ovalCustomization.progressColor1 = theme.instructions.ovalCam.spinner1;
        defaultCustomization.ovalCustomization.progressColor2 = theme.instructions.ovalCam.spinner2;
        defaultCustomization.ovalCustomization.progressStrokeWidth = theme.instructions.ovalCam.spinnerStrokeWidth;
        defaultCustomization.feedbackCustomization.backgroundColor = theme.instructions.feedbackBar.backgroundColor;
        defaultCustomization.feedbackCustomization.textColor = theme.instructions.feedbackBar.textColor;
        defaultCustomization.feedbackCustomization.shadow = theme.instructions.feedbackBar.shadow;
        defaultCustomization.feedbackCustomization.cornerRadius = theme.instructions.feedbackBar.borderRadius;
        defaultCustomization.cancelButtonCustomization.customImage = theme.cancelButton;
        defaultCustomization.cancelButtonCustomization.location = FaceTecSDK.FaceTecCancelButtonLocation.TopLeft;
    };
    var customizeFinishingUp = function () {
        defaultCustomization.resultScreenCustomization.backgroundColors = theme.finishingUp.backgroundColor;
        defaultCustomization.resultScreenCustomization.foregroundColor = theme.finishingUp.textColor;
        defaultCustomization.resultScreenCustomization.activityIndicatorColor = theme.finishingUp.circle;
        defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = theme.finishingUp.uploadProgress;
        defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = theme.finishingUp.backgroundColor;
        defaultCustomization.resultScreenCustomization.uploadProgressFillColor = theme.finishingUp.uploadProgress;
    };
    customizeInitialLoading();
    customizeInstructions();
    customizeRetry();
    customizeFinishingUp();
    return defaultCustomization;
};
