import React from 'react';
import LogoPaketa from '../../assets/images/png/logo-paketa-horizontal.png';
import Button from '../Button';
import { ActionsContentStyled, IconContentStyled, InfoContent, StepContainerStyled, StepContentStyled, SubtitleInfoStyled, TitleInfoStyled } from './styles';
var Stepper = function (_a) {
    var steps = _a.steps, activeStep = _a.activeStep, onNextStep = _a.onNextStep;
    if (activeStep >= steps.length) {
        return null;
    }
    return (React.createElement(StepContainerStyled, null,
        React.createElement("header", null,
            React.createElement("img", { src: LogoPaketa, alt: "paketa logo" })),
        React.createElement(StepContentStyled, null,
            React.createElement(IconContentStyled, null,
                React.createElement("img", { src: steps[activeStep].icon, alt: "selfie icon" })),
            React.createElement(InfoContent, null,
                React.createElement(TitleInfoStyled, null, steps[activeStep].title),
                React.createElement(SubtitleInfoStyled, null, steps[activeStep].subTitle))),
        React.createElement(ActionsContentStyled, null,
            React.createElement(Button, { variant: 'contained', onClick: onNextStep }, " Pr\u00F3ximo "),
            React.createElement(Button, { variant: 'text', onClick: function () { return console.log('Cancelar'); } }, " Cancelar "))));
};
export default Stepper;
